import NavigationBar from "./Nav";
import Footer from "./Footer";
import BackToTopButton from './BacktoTop';
import { Slideshow } from "./Slideshow";
import DisableRightClick from './NoRightClick';

import img from './assets/cuz/00.svg'
import img2 from './assets/cuz/01.svg'
import img3 from './assets/cuz/02.svg'
import img4 from './assets/cuz/03.svg'
import img5 from './assets/cuz/04.svg'
import img6 from './assets/cuz/05.svg'
import img7 from './assets/cuz/06.svg'
import img8 from './assets/cuz/07.svg'
import img9 from './assets/cuz/08.svg'
import img10 from './assets/cuz/09.svg'
import img11 from './assets/cuz/010.svg'
import img12 from './assets/cuz/011.svg'
import img13 from './assets/cuz/012.svg'
import img14 from './assets/cuz/013.svg'
import img15 from './assets/cuz/014.svg'
import img16 from './assets/cuz/015.svg'
import img17 from './assets/cuz/016.svg'
import img18 from './assets/cuz/017.svg'
import img19 from './assets/cuz/018.svg'
import img20 from './assets/cuz/019.svg'
import img21 from './assets/cuz/020.svg'
import img22 from './assets/cuz/021.svg'
import img23 from './assets/cuz/022.svg'
import img24 from './assets/cuz/023.svg'
import img25 from './assets/cuz/024.svg'
import img26 from './assets/cuz/025.svg'
import img27 from './assets/cuz/026.svg'


const galleryData = {
    title: "Cuz Sushi",
    subtitle: "Sushi Customization App",
    images:[
        {src: img, alt: 'title page',},
        {src: img2, alt: 'project overview',},
        {src: img3, alt: 'project overview',},
        {src: img4, alt: 'project overview',},
        {src: img5, alt: 'Understanding the user'},
        {src: img6, alt: 'user research summary'},
        {src: img7, alt: 'user research pain points'},
        {src: img8, alt: 'user research persona'},
        {src: img9, alt: 'user research user journey map'},
        {src: img10, alt: 'starting the design'},
        {src: img11, alt: 'paper wireframes'},
        {src: img12, alt: 'digital wireframes'},
        {src: img13, alt: 'digital wireframes'},
        {src: img14, alt: 'lo-fi prototype', link:'https://www.figma.com/proto/ukOiZ2pUcOwaK0IOAoOw39/Untitled?node-id=1%3A85&scaling=scale-down&page-id=0%3A1&starting-point-node-id=1%3A85'},
        {src: img15, alt: 'usability study findings'},
        {src: img16, alt: 'section title, refining the design'},
        {src: img17, alt: 'mockup showcase - changes made on design decisions'},
        {src: img18, alt: 'mockup showcase - changes made on design decisions'},
        {src: img19, alt: 'mockup showcase'},
        {src: img20, alt: 'mockup showcase'},
        {src: img21, alt: 'mockup Style guide'},
        {src: img22, alt: 'hi-fi prototype', link:'https://www.figma.com/proto/7zIFIRwwOYlLUDjbEbUzq6/Cuz-Sushi-prototype?page-id=219%3A137&node-id=300%3A65&viewport=297%2C426%2C0.23&scaling=scale-down&starting-point-node-id=300%3A65&show-proto-sidebar=1'},
        {src: img23, alt: 'accessibility consideration list'},
        {src: img24, alt: 'section title, Going Forward'},
        {src: img25, alt: 'takeaways, app impact and what designer learned from this project'},
        {src: img26, alt: "project's next step"},
        {src: img27, alt: 'page for connect'},
    ],
}; 

export default function CuzSushi(){
    
    return (
    <div className="slides">
    <NavigationBar/>
    <BackToTopButton/>
    <DisableRightClick>
    <Slideshow title={galleryData.title} subtitle={galleryData.subtitle} images={galleryData.images} /> 
    </DisableRightClick>
    <footer>
        <hr></hr>
        <Footer/>
    </footer>
    </div>
    );
}

  
  
