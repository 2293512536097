import { useInView } from "framer-motion";
import { useRef } from "react";
import { Image } from 'react-bootstrap';

import avatar from './assets/avatar.svg'

function InView({ children , id}) {
    const ref = useRef(null);
    const isInView = useInView(ref, { 
      once: false,
      margin: "0px 100px -50px 0px"
    });
  
    return (
      <div ref={ref} id = {id}>
        <span
          style={{
            transform: isInView ? "none" : "translateX(-200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
          }}
        >
          {children}
        </span>
      </div>
    );
  }

export default function AboutMe (){
    return (
        <>
          <InView><h3><Image src={avatar} id='avatar'/>About Alice</h3></InView>
          <InView>
            <p>Welcome to Alice’s creative world! She's an artist who embraces diverse forms of expression, from interactive arts to traditional paintings. Through her work, she pushes boundaries, exploring the fusion of art and technology.</p></InView>
          <InView>
            <p>Drawing inspiration from her surroundings, Alice expresses her unique perspective through paintings, sculptures, and digital art. In the realm of interactive media, she crafts installations and digital experiences that bridge the physical and virtual, encouraging active participation and sparking curiosity. As a UX designer, she blends artistry with a user-centric approach, creating intuitive and visually pleasing designs that elevate interactions with digital products.</p></InView>
          <InView>
            <p>Alice's collaborative projects showcase her evolution as a creative professional. Explore her portfolio to uncover the transformative synergy of art and design! </p></InView>
        </>
      );
}