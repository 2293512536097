import { useState } from "react";
import { Image, Container} from 'react-bootstrap';

export function Slideshow (props) {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageSrc, imageAlt) => {
    setSelectedImage({ src: imageSrc, alt: imageAlt });
  };

  const handleClose = () => {
    setSelectedImage(null);
  };
     return (
    <div>
       <h1>{props.title}</h1>
       <h2>{props.subtitle}</h2> 
      <Container className="slideShowImg"> 
      {props.images.map((image, index) => {
          // check whether the item is a video
          if (image.type === 'video') {
            return (
              <video key={index} controls>
                <source src={image.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            );
          }
          // check whether the image has a link
          else if (image.link) {
            return (
              <a href={image.link} key={index} target="_blank" rel="noreferrer">
                <img src={image.src} alt={image.alt} onClick={() => handleImageClick(image.src, image.alt)} />
                Visit this prototype
              </a>
            );
          }
          // check whether the item is only an image
          else {
            return (
              <Image key={index} src={image.src} alt={image.alt} onClick={() => handleImageClick(image.src, image.alt)} />
            );
          }
        })
      }

      {selectedImage && (
        <div id="dsplay" className='modal' style={{ display: "block" }}>
          <span className="close" onClick={handleClose}>&times;</span>
          <img id="theImg" className='modal-content' src={selectedImage.src} alt={selectedImage.alt} />
          <div id="caption">{selectedImage.alt}</div>
        </div>
      )}
        {/* {props.images.map((image, index)=>(
          image.link 
          ? <a href={image.link} key={index} target="_blank" rel="noreferrer">
            <img src={image.src} alt={image.alt} onClick={() => handleImageClick(image.src, image.alt)}/>
            Visit this prototype
            </a>
          :<Image key={index} src={image.src} alt={image.alt} onClick={() => handleImageClick(image.src, image.alt)}/>
        ))}

        {selectedImage && (
        <div id="dsplay" className='modal'style={{display: "block"}}>
          <span className="close" onClick={handleClose}>&times;</span>
          <img id="theImg" className='modal-content' src={selectedImage.src} alt={selectedImage.alt} />
          <div id="caption">{selectedImage.alt}</div>
        </div>
      )} */}
      </Container>
    </div>
  );
}

