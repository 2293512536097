import { Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './css/style.css';
import logo from "./assets/tLogo.svg";

function NavigationBar() {
  return (
    <Navbar expand="lg" className="homNav bg-body-tertiary "> 
       <Container fluid className="homNav container-fluid">
        <Navbar.Brand href="/"><img src={logo} id='logo' alt='text logo written wenxin'/> </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" /> 
          <Navbar.Collapse id="basic-navbar-nav"> 
          <Nav className="ms-auto">
          <LinkContainer to="/" className='nav-link'>
            <Nav.Link className='nav-item'>Home</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/Portfolio" className='nav-link'>
            <Nav.Link className='nav-item'>Portfolio</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/Resume">
            <Nav.Link className='nav-item'>Resume</Nav.Link>
          </LinkContainer>
          </Nav>
    </Navbar.Collapse> 
      </Container>
    </Navbar>
  );
}

export default NavigationBar;