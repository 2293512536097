import NavigationBar from "./Nav";
import Footer from "./Footer";
import Display from './DisplaySections';
import BackToTopButton from './BacktoTop';
import DisableRightClick from './NoRightClick';
import './css/style.css';


export default function Portfolio() {
    return (
      <div>
        <NavigationBar/>
        <DisableRightClick>
        <Display/>
        </DisableRightClick>
        <BackToTopButton/>
        <footer>
        <hr></hr>
        <Footer/>
        </footer>
      </div>
    );
  }