import { useRef } from "react";
import { Link } from 'react-router-dom';
import {
  motion,
  useScroll,
  useTransform
} from "framer-motion";
import { Button } from 'react-bootstrap';

import cuz from './assets/cuz01.svg';
import cuz2 from './assets/cuz02.svg';
import cuz3 from './assets/cuz03.svg';
import cuz4 from './assets/cuz04.svg';
import cuz5 from './assets/cuz05.svg';

import gro from './assets/gro01.svg';
import gro2 from './assets/gro02.svg';
import gro3 from './assets/gro03.svg';
import gro4 from './assets/gro04.svg';
import gro5 from './assets/gro06.svg';

import data from './assets/data01.svg';
import data2 from './assets/data02.svg';
import data3 from './assets/data03.svg';
import data4 from './assets/data04.svg';
import data5 from './assets/data05.svg';

import meow from './assets/meow01.svg';
import meow2 from './assets/meow02.svg';
import meow3 from './assets/meow03.svg';
import meow4 from './assets/meow04.svg';
import meow5 from './assets/meow05.svg';

import newton from './assets/newton01.gif';
import newton2 from './assets/newton02.gif';
import newton3 from './assets/newton03.gif';

const imageGroups = [
    [data, data2, data3, data4, data5],
    [gro, gro2, gro3, gro4, gro5],
    [cuz4, cuz5, cuz, cuz2, cuz3],
    [meow, meow2, meow3, meow4, meow5],
    [newton, newton2, newton3]   
];

const titles = ['UX Projects', 'Interactive Projects'];
const projectTitles = ['Notification System', 'Grocero Find', 'Cuz Sushi', 'Meow Box', 'Become Newton'];
const linkAddress = ['/Centralized', '/GroceoFind', '/CuzSushi', '/QuantumCat', '/newton'];

function useParallax(value, distance) {
  return useTransform(value, [0, 1], [-distance, distance]);
}

function ImageGroup({ imageGroup, id }) {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref });
    const isMobile = window.innerWidth <= 576; 
    const distance = isMobile ? 0 : 180;
    const distance2 = isMobile ? 0 : 300;
    const y =  useParallax(scrollYProgress, distance);
    const y2 = useParallax(scrollYProgress, distance2);
  
    return (
        <>
        {id === 0 && <h2>{titles[0]}</h2>}
        {id === 3 && <h2>{titles[1]}</h2>}
        <section ref={ref}>
            <motion.h3 style={{ y: y2 }} >{projectTitles[id]}</motion.h3>
            <div className="image-grid" >
                {imageGroup.map((imageSrc, index) => (
                    <motion.img key={index} src={imageSrc} style={{ y }} alt={`Image ${id}-${index}` } />
                ))}
            </div>
            <Link to ={linkAddress[id]}>
            <Button variant="primary" size="lg"> Explore this project </Button>
            </Link>
        </section>
        </>
    );
}

  export default function Display() {
    return (
      <div className="portfolioSection">
        {imageGroups.map((imageGroup, index) => (
          <ImageGroup key={index} imageGroup={imageGroup} id={index} />
        ))}
      </div>
    );
}
