// import { Link, Outlet } from "react-router-dom";
import React from 'react';
import NavigationBar from "./Nav";
import Footer from "./Footer";

const schoolName = [
  'Google + Coursera',
  'Sheridan College, ON',
  'University of Waterloo, ON',
];

function Header() {
  return (
    <header className="resume-header">
      <h1>Wen Xin Zhao</h1>
    </header>
  );
}

function Skills() {
  const skills = [
    'User Research',
    'Wireframing & Prototyping',
    'Accessibility & Inclusive Design',
    'Collaboration & Communication',
    'Technical Skills'
  ];

  return (
    <section className="skills-section">
      <h2>SKILLS</h2>
      <ul className='gridLayout'>
        {skills.map(skill => (
          <li key={skill}>{skill}</li>
        ))}
      </ul>
    </section>
  );
}

function Education() {
  const programTitles = [
    'Google UX Design Certificate',
    'Digital Product Design',
    'Interactive Media Management',
    'Bachelor of Art - Fine Arts Studio',
  ];
  const graduationDate = [
    '05/2022 - Ongoing',
    '09/2022 -',
    '09/2021 - 06/2022',
    '09/2016 - 06/2020',
  ];
  const educationDetails = [
    'Graduate Certificate',
    'Digital Arts Specialization',
    'Dean’s Honours List'
  ];
  
  return (
    <section className="education-section">
      <h2>EDUCATION</h2>
      <div className='gridLayout'> 
        <div>
          <p className='lightWeight'>{graduationDate[0]}</p>
          <h3>{programTitles[0]}</h3>
          <p>{schoolName[0]}</p>
        </div>

        <div>
          <p className='lightWeight'>{graduationDate[1]}</p>
          <h3>{programTitles[1]}</h3>
          <p>{educationDetails[0]}</p>
          <p>{schoolName[1]}</p>
        </div>

        <div>
          <p className='lightWeight'>{graduationDate[2]}</p>
          <h3>{programTitles[2]}</h3>
          <p>{educationDetails[0]}</p>
          <p>{schoolName[1]}</p>
        </div>

        <div>
          <p className='lightWeight'>{graduationDate[3]}</p>
          <h3>{programTitles[3]}</h3>
          <p>{educationDetails[1]}</p>
          <p>{educationDetails[2]}</p>
          <p>{schoolName[2]}</p>
        </div>
      </div>
    </section>
  );
}

function Experience() {
  const jobTitles = [
    'Product Manager - Internship',
    'Program Tutor',
    'Special Collections & Archives Project Assistant'
  ];
  const times= [
    '01/2023 - 04/2023',
    '01/2022 - 04/2022',
    '01/2018 - 04/2018'
  ]
  const job1 = [
    'Conducted in-depth research into the company’s market position, contributing to a tailored product development strategy that aligned with client expectations',
    'Developed a comprehensive project requirements document that served as a critical reference for the development team, enhancing project clarity and direction',
    'Actively participated in project reviews, providing key insights that led to significant enhancements in product usability and customer experience',
    'Created detailed wireframes and prototypes that facilitated clear communication of design intent and functionality to stakeholders and the development team',
  ];
  const job2 = [
    'Delivered targeted instructional sessions to students, resulting in regained confidence and noticeable improvements in their academic performance',
    'Crafted and distributed study materials that simplified complex subjects, enabling better student comprehension and learning efficiency',
    'Established a follow-up routine with students to reinforce learning objectives and provide additional support, which was well-received and appreciated',
  ];
  const job3 = [
    'Designed engaging and informative marketing materials, such as postcards and bookmarks, enhancing the visibility and appeal of the archival department',
    'Meticulously scanned and cataloged photo negatives, significantly improving the organization and accessibility of the archival collection for research purposes',
    'Composed and published detailed blog posts that effectively communicated the value and contents of the archive to a broader audience, fostering greater community engagement',
  ];

  return (
    <section className="experience-section">
      <h2>RELEVANT EXPERIENCES</h2>
      <div>
        <h3>{jobTitles[0]}</h3>
        <h3>{times[0]}</h3>
        <p className='lightWeight'>Zhengzhou Xindajiean Information Technology Co.Ltd.</p>
        <ul>
          {job1.map(duty => (
            <li key={duty}>{duty}</li>
          ))}
        </ul>
      </div>
     
      <div>
        <h3>{jobTitles[1]}</h3>
        <h3>{times[1]}</h3>
        <p className='lightWeight'>{schoolName[1]}</p>
        <ul>
          {job2.map(duty => (
            <li key={duty}>{duty}</li>
          ))}
        </ul>
      </div>
     
      <div>
        <h3>{jobTitles[2]}</h3>
        <h3>{times[2]}</h3>
        <p className='lightWeight'>{schoolName[2]}</p>
        <ul>
          {job3.map(duty => (
            <li key={duty}>{duty}</li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default function Resume() {
    return (
      <>
        <NavigationBar/>
        <Header />
        <hr></hr>
        <main className='resume'> 
          <div className='grid1'><Skills/></div>
          <div className='grid2'><Education /></div>
          <div className='grid3'><Experience /></div>
        </main>
        <footer>
        <hr></hr>
        <Footer/>
        </footer>
      </>
    );
  }