import NavigationBar from "./Nav";
import Footer from "./Footer";
import { Slideshow } from "./Slideshow";
import BackToTopButton from './BacktoTop';
import DisableRightClick from './NoRightClick';

import img from './assets/gro/001.svg'
import img2 from './assets/gro/002.svg'
import img3 from './assets/gro/003.svg'
import img4 from './assets/gro/004.svg'
import img5 from './assets/gro/005.svg'
import img6 from './assets/gro/006.svg'
import img7 from './assets/gro/007.svg'
import img8 from './assets/gro/008.svg'
import img9 from './assets/gro/009.svg'
import img10 from './assets/gro/010.svg'
import img11 from './assets/gro/011.svg'
import img12 from './assets/gro/012.svg'
import img13 from './assets/gro/013.svg'
import img14 from './assets/gro/014.svg'
import img15 from './assets/gro/015.svg'
import img16 from './assets/gro/016.svg'
import img17 from './assets/gro/017.svg'
import img18 from './assets/gro/018.svg'
import img19 from './assets/gro/019.svg'
import img20 from './assets/gro/020.svg'
import img21 from './assets/gro/021.svg'
import img22 from './assets/gro/022.svg'
import img23 from './assets/gro/023.svg'
import img24 from './assets/gro/024.svg'
import img25 from './assets/gro/025.svg'
import img26 from './assets/gro/026.svg'
import img27 from './assets/gro/027.svg'
import img28 from './assets/gro/028.svg'
import img29 from './assets/gro/029.svg'
import img30 from './assets/gro/030.svg'
import img31 from './assets/gro/031.svg'
import img32 from './assets/gro/032.svg'
import img33 from './assets/gro/033.svg'
import img34 from './assets/gro/034.svg'
import img35 from './assets/gro/035.svg'
import img36 from './assets/gro/036.svg'
import img37 from './assets/gro/037.svg'
import img38 from './assets/gro/038.svg'
import img39 from './assets/gro/039.svg'
import img40 from './assets/gro/040.svg'

const galleryData = {
    title: "Groceo Find",
    subtitle: "Grocery Shopping Assistant",
    images:[
        {src: img, alt: 'title page',},
        {src: img2, alt: 'project intro',},
        {src: img3, alt: 'project intro - about the observation',},
        {src: img4, alt: 'project overviproject intro - about the observation location',},
        {src: img5, alt: 'setting the observation conditions'},
        {src: img6, alt: 'interesting discoveries of the observation'},
        {src: img7, alt: 'interesting discoveries of the observation'},
        {src: img8, alt: 'interesting discoveries of the observation'},
        {src: img9, alt: 'brainstorm section page'},
        {src: img10, alt: 'brainstorming ideas'},
        {src: img11, alt: 'brainstorming ideas'},
        {src: img12, alt: 'brainstorming conclusions'},
        {src: img13, alt: 'hypothesis section page'},
        {src: img14, alt: 'hypothesis'},
        {src: img15, alt: 'hypothesis'},
        {src: img16, alt: 'hypothesis'},
        {src: img17, alt: 'hypothesis'},
        {src: img18, alt: 'ERAF system section page'},
        {src: img19, alt: 'ERAF system finding one'},
        {src: img20, alt: 'ERAF system finding two'},
        {src: img21, alt: 'project intervention'},
        {src: img22, alt: 'project intervention - the goal'},
        {src: img23, alt: 'wireframe section page'},
        {src: img24, alt: 'wireframe'},
        {src: img25, alt: 'wireframe'},
        {src: img26, alt: "wireframe"},
        {src: img27, alt: 'wireframe - primary flow'},
        {src: img28, alt: 'mockups and style tiles section page'},
        {src: img29, alt: 'style tiles'},
        {src: img30, alt: 'mockups showcase'},
        {src: img31, alt: 'mockups showcase'},
        {src: img32, alt: 'mockups showcase'},
        {src: img33, alt: 'prototype section page'},
        {src: img34, alt: 'prototype link', link:'https://www.figma.com/file/RTAstmJ1DOl2OrrXYiPr2s/Grocery-Assistance?type=design&node-id=29%3A719&mode=design&t=yeGhQUjMdKlBHfDr-1'},
        {src: img35, alt: 'appendix section page'},
        {src: img36, alt: 'appendix - user flow'},
        {src: img37, alt: 'appendix - indoor map concept art'},
        {src: img38, alt: 'appendix - accessibility considerations display'},
        {src: img39, alt: 'appendix - design and final iterations'},
        {src: img40, alt: 'thank you for reviewing my works'},
    ],
}; 

export default function GroceoFind(){
    
    return (
    <div className="slides">
    <NavigationBar/>
    <BackToTopButton/>
    <DisableRightClick>
    <Slideshow title={galleryData.title} subtitle={galleryData.subtitle} images={galleryData.images} /> 
    </DisableRightClick>
    <footer>
        <hr></hr>
        <Footer/>
    </footer>
    </div>
    );
}

  
  
