import { useRef, useState} from "react";
import { Link } from 'react-router-dom';
import { useInView } from "framer-motion";
import { Button, Card, Collapse, Container } from 'react-bootstrap';

import cuz from './assets/CuzI.svg'
import cuzAlt from './assets/cuzA.svg'
import email from './assets/emailI.svg'
import emailAlt from './assets/emailA.svg'
import gro from './assets/groWI.svg'
import groAlt from './assets/groWA.svg'
import meowBox from './assets/meowBoxI.svg'
import meowBoxAlt from './assets/meowBoxA.svg'
import newton from './assets/NewI.svg'
import newtonAlt from './assets/NewA.svg'

const cardTitles=["Cuz Sushi","Notification System","Groceo Find-Watch","Become Newton","Meow Box"]
const texts=["Developing an intuitive email notification system to alert users of upcoming product license expirations, ensuring timely renewals and continuous service.",
"Creating a user-friendly sushi customization app that enables users to craft their perfect sushi roll with their choice of fresh ingredients.", "Designing a convenient grocery list and locator app for the Apple Watch to streamline the shopping experience by guiding users to items in-store efficiently.", 
"Build an Arduino-powered interactive box that reacts to approaching people, inspired by the concept of Schrödinger's cat, to explore the interplay of presence and response.",
"Developing a playful game where players assist Newton the cat in a whimsical quest to be hit by a falling apple, combining humor and strategy",]

function Projects({assignedRef, spaceShift, image, imageAlt, text, linkAddress, 
tag, tag2, tag3, tag4, index, isSelected, onSelect, isToggled, toggleImage, id}) {    
  return (  
    <Card style={{ width: "20rem"}} 
      className={isSelected ? "selected" : "not-selected"} //changing style for the selected work
      ref={assignedRef} //scroll to the designiated position 
      onClick={()=>{onSelect(); spaceShift()}}> 
        <Card.Img 
        variant="top" 
        src={isToggled ? imageAlt : image}//switch between img & its alt
        alt="Toggleable"
        onClick={() => toggleImage(id)}//click the image to expand and collapse a card
        aria-controls="collapse-content" 
        aria-expanded={isToggled}/> 
      <Collapse in={isToggled}>
        <div id="collapse-content">
          <Card.Body>
            <Card.Title>{cardTitles[index]}</Card.Title>
            <Card.Text>
              {text}
            </Card.Text>
            <Link to ={linkAddress}>
            <Button variant="primary">Learn more</Button>
            </Link>
          </Card.Body>
        </div>
      </Collapse>
      <Card.Body>
      {tag && <div className="tag">{tag}</div>}
      {tag2 && <div className="tag">{tag2}</div>}
      {tag3 && <div className="tag">{tag3}</div>}
      {tag4 && <div className="tag">{tag4}</div>}
      </Card.Body>
    </Card>
  );
}

function Section({ children , id}) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  return (
    <div ref={ref} id = {id}>
      <span
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}
      >
        {children}
      </span>
    </div>
  );
}

export default function HorizontalScroll() {
    const [selectedImg, setSelectedImg] = useState(null);//set the card style before/after clicking
    
    const [selectedCard, setSelectedCard] = useState(null); // toggle on/off for the collapsed card
    
    const scrollContainerRef = useRef(null);
    const imgRef = useRef(null);
    const imgRef2 = useRef(null);
    const imgRef3 = useRef(null);
    const imgRef4 = useRef(null);
    const imgRef5 = useRef(null);

    //controlling the horizontal scroll of the images
    const handleSelectImage = (imgRef) => {
        if (scrollContainerRef.current && imgRef.current) {
        const scrollContainer = scrollContainerRef.current;
        const selectedImg = imgRef.current;

        // calculate the scroll distance
        const scrollX = selectedImg.offsetLeft - (scrollContainer.offsetWidth / 2) + (selectedImg.offsetWidth / 2);
        
        // smoothly scroll to the desiganated point 
        scrollContainer.scrollTo({ left: scrollX, behavior: 'smooth' });
        }
    };

  return (
      <>
      <Container id="hltWork" ref={scrollContainerRef}>
      {/* <Line className="progress-bar"/> */}
        <Section><Projects assignedRef={imgRef} id='project1' image={email} imageAlt={emailAlt} text={texts[0]} linkAddress='/Centralized' tag="Axure" index={1} isSelected={selectedImg === email} onSelect={() => setSelectedImg(email)} spaceShift={() =>handleSelectImage(imgRef)} isToggled={selectedCard==='project1'} 
        toggleImage={() => setSelectedCard(selectedCard === "project1" ? null : "project1")}/></Section>
        <Section><Projects assignedRef={imgRef2} id='project2'image={cuz} imageAlt={cuzAlt} text={texts[1]} linkAddress='/CuzSushi' tag="Figma"  index={0} isSelected={selectedImg === cuz} onSelect={() => setSelectedImg(cuz)} spaceShift={() =>handleSelectImage(imgRef2)} isToggled={selectedCard==='project2'} 
        toggleImage={() => setSelectedCard(selectedCard === "project2" ? null : "project2")}/></Section>
        <Section><Projects assignedRef={imgRef3} id='project3' image={gro} imageAlt={groAlt} text={texts[2]} linkAddress='/GroceoFindWatch' tag="Figma" index={2} isSelected={selectedImg === gro} onSelect={() => setSelectedImg(gro)} spaceShift={() =>handleSelectImage(imgRef3)} isToggled={selectedCard==='project3'} 
        toggleImage={() => setSelectedCard(selectedCard === "project3" ? null : "project3")}/></Section>
        <Section><Projects assignedRef={imgRef4} id='project4' image={meowBox} imageAlt={meowBoxAlt}text={texts[3]} linkAddress='/QuantumCat' tag="Arduino" tag2="Procreate" tag3="Figma" index={4}isSelected={selectedImg === meowBox} onSelect={() => setSelectedImg(meowBox)} spaceShift={() =>handleSelectImage(imgRef4)} isToggled={selectedCard==='project4'} toggleImage={() => setSelectedCard(selectedCard === "project4" ? null : "project4")}/></Section>
        <Section><Projects assignedRef={imgRef5} id='project5' image={newton} imageAlt={newtonAlt}text={texts[4]} linkAddress='/newton' tag="ZimJS" tag2="Procreate" tag3="ThreeJS" tag4="Figma" index={3} isSelected={selectedImg === newton} onSelect={() => setSelectedImg(newton)} spaceShift={() =>handleSelectImage(imgRef5)} isToggled={selectedCard==='project5'} toggleImage={() => setSelectedCard(selectedCard === "project5" ? null : "project5")}/></Section>
      </Container>
    </>
  );
}
