import { motion } from 'framer-motion';

export default function AnimatedText() {
  const text = "Design is really an act of communication, which means having a deep understanding of the person with whom the designer is communicating.";
  const words = text.split("");

  return (
    <h2 className='container' id='unqTitle'>
      {words.map((word, index) => (
        <motion.span
          key={index}
          whileInView={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: index * 0.08 }}
        >
          {word}
        </motion.span>
      ))}
      <p>
        <br/><i>― Donald A. Norman, The Design of Everyday Things</i>
      </p>
    </h2>
  );
}
