import React from 'react';
import NavigationBar from "./Nav";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import HorizontalScroll from './HorizontalScroll';
import AnimatedText from './TextAnimation';
import Skills from './SkillEx';
import BackToTopButton from './BacktoTop';
import AboutMe from './AboutSection';
import DisableRightClick from './NoRightClick';

// image assets importing down below
import homePage from './assets/desert02.jpg'
import portfolio from './assets/desert01.jpg'
import resume from './assets/desert04.jpg'

import Carousel from 'react-bootstrap/Carousel';

function SlideShow() {
  return (
    <Carousel fade>
      <Carousel.Item>
        <Link to="/">
          <img src={homePage} className="d-block w-100 wideImage"alt="UX project - sushi customization app"/>
        </Link>
        <Carousel.Caption>
          <h1>Wen Xin Studio</h1>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Link to="/Portfolio">
          <img src={portfolio} className="wideImage" alt="UX project - sushi customization app - process work"/>
        </Link>
        <Carousel.Caption>
          <h1>Portfolio</h1>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Link to="/Resume">
          <img src={resume} class="wideImage" alt="UX project - sushi customization app - workflow"/>
        </Link>
        <Carousel.Caption>
          <h1>Resume</h1>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

function Home() {
  return (
    <div>    
    <NavigationBar/>
    <DisableRightClick>

      <section className= "landingPage">
      <SlideShow/>
      </section>

      <BackToTopButton/>

      <section id="showCase">
        <h2>Highlighted Works</h2>
        <HorizontalScroll/>
        <AnimatedText/>
      </section>
    
      <section className = "container light-background home">
        <h3>Experience & Education</h3>
        <Skills/>
        <AboutMe/>    
      </section>

    </DisableRightClick>
    <footer>
      <hr></hr>
      <Footer/> 
    </footer>
  </div>
  );
}

export default function App(){
  return <Home />;
}

