import NavigationBar from "./Nav";
import Footer from "./Footer";
import { Slideshow } from "./Slideshow";
import BackToTopButton from './BacktoTop';
import DisableRightClick from './NoRightClick';

import img from './assets/growatch/001.svg'
import img2 from './assets/growatch/002.svg'
import img3 from './assets/growatch/003.svg'
import img4 from './assets/growatch/004.svg'
import img5 from './assets/growatch/005.svg'
import img6 from './assets/growatch/006.svg'
import img7 from './assets/growatch/007.svg'
import img8 from './assets/growatch/008.svg'
import img9 from './assets/growatch/009.svg'
import img10 from './assets/growatch/010.svg'
import img11 from './assets/growatch/011.svg'


const galleryData = {
    title: "Groceo Find",
    subtitle: "Apple Watch version",
    images:[
        {src: img, alt: 'title page',},
        {src: img2, alt: 'project overview section page',},
        {src: img3, alt: 'project overview - introducing the goal',},
        {src: img4, alt: 'style sheet section page',},
        {src: img5, alt: 'style sheet - font choice, explaning the design decisions'},
        {src: img6, alt: 'style sheet - colour palette'},
        {src: img7, alt: 'mockup section page'},
        {src: img8, alt: 'mockup showcase'},
        {src: img9, alt: 'mockup showcase'},
        {src: img10, alt: 'mockup showcase + prototype link', link:'https://www.figma.com/file/RTAstmJ1DOl2OrrXYiPr2s/Grocery-Assistance?type=design&node-id=442:1778&mode=design&t=57MrvhJT5mXdpIm0-1'},
        {src: img11, alt: 'take aways section page'},
    ],
}; 

export default function GroceoFindWatch(){
    
    return (
    <div className="slides">
    <NavigationBar/>
    <BackToTopButton/>
    <DisableRightClick>
    <Slideshow title={galleryData.title} subtitle={galleryData.subtitle} images={galleryData.images} /> 
    </DisableRightClick>
    <footer>
        <hr></hr>
        <Footer/>
    </footer>
    </div>
    );
}

  
  
