import React from 'react';

class DisableRightClick extends React.Component {
  componentDidMount() {
    document.addEventListener('contextmenu', this.handleRightClick);
  }

  componentWillUnmount() {
    document.removeEventListener('contextmenu', this.handleRightClick);
  }

  handleRightClick = (event) => {
    event.preventDefault(); // 阻止默认行为，即禁止右键菜单
  }

  render() {
    return (
      <div>
        {this.props.children} {/* 渲染任何子组件 */}
      </div>
    );
  }
}

export default DisableRightClick;
