import { useRef} from "react";
import { useInView} from "framer-motion";
import {Container, Row, Figure, Col} from 'react-bootstrap';

import ps from './assets/photoshop.svg'
import ai from './assets/illustrator.svg'
import figma from './assets/figma.svg'
import xd from './assets/xd.svg'
import html from './assets/html.svg'
import reactIcon from './assets/react.svg'
import zim from './assets/zim.svg'

const yearList=['23', '22', '21', '18', '16-20'];
const schoolName=['University of Waterloo, ON', 'Sheridan College, ON', 'Google + Coursera'];
const programName=['Google UX Design Certificate', 'Digital Product Design', 'Interactive Media Management', 'Bachelor of Art - Fine Arts Studio'];
const positionName=['Product Manager-Intern', 'Program Tutor', 'Special Collection & Archive Project Assistant'];


function TimeLine({year, title, name, additional, additional2}) {
    return(
        <Container className="skillSection">
            {year && <h3>{year}</h3>}
            <p className="bold">{title}</p>
            {additional && <p className="lightWeight">{additional}</p>}
            <p className="lightWeight">{name}</p>
            {additional2 && <p className="lightWeight"> {additional2}</p>}
        </Container>
    );
}

function FamiliarApp() {
    return(
        <Container className="skillSection">
            <Row>
                <Col>
                    <Figure>
                        <Figure.Image alt="171x180" src={ps}/>
                        <Figure.Caption>
                        Photoshop
                        </Figure.Caption>
                    </Figure>
                    <Figure>
                        <Figure.Image alt="171x180" src={ai}/>
                        <Figure.Caption>
                        Illustrator
                        </Figure.Caption>
                    </Figure>
                    <Figure>
                        <Figure.Image alt="171x180" src={figma}/>
                        <Figure.Caption>
                        Figma
                        </Figure.Caption>
                    </Figure>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Figure>
                        <Figure.Image alt="171x180" src={xd}/>
                        <Figure.Caption>
                        Adobe Xd
                        </Figure.Caption>
                    </Figure>
                    <Figure>
                        <Figure.Image alt="171x180" src={html}/>
                        <Figure.Caption>
                        HTML & CSS
                        </Figure.Caption>
                    </Figure>
                    <Figure>
                        <Figure.Image alt="171x180" src={reactIcon}/>
                        <Figure.Caption>
                        React
                        </Figure.Caption>
                    </Figure>
                    <Figure>
                        <Figure.Image alt="171x180" src={zim}/>
                        <Figure.Caption>
                        ZimJS
                        </Figure.Caption>
                    </Figure>
                </Col>
            </Row>
        </Container>
    );
}

function Section({ children , id}) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  return (
    <div ref={ref} id = {id}>
      <span
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}
      >
        {children}
      </span>
    </div>
  );
}

export default function Skills() {
    
  return (
    <>
      <Section><TimeLine year={yearList[0]} title={positionName[0]} name="Zhengzhou Xindajiean Information Technology Co.Ltd."/></Section>
      <Section><TimeLine year={yearList[1]} title={programName[0]} name={schoolName[2]}/></Section>
      <Section><TimeLine title={programName[1]} additional="Graduate Certificate" name={schoolName[1]}/></Section>
      <Section><TimeLine title={positionName[1]} name={schoolName[1]}/></Section>
      <Section><TimeLine year={yearList[2]} title={programName[2]} additional="Graduate Certificate" name={schoolName[1]}/></Section>
      <Section><TimeLine year={yearList[3]} title={positionName[2]}  name={schoolName[0]}/></Section>
      <Section><TimeLine year={yearList[4]} title={programName[3]} additional="Digital Arts Specialization" name={schoolName[0]} additional2="Dean’s Honours List"/></Section>
      <Section><h3>Technical Skills</h3></Section>
      <Section><FamiliarApp/></Section>
    </>
  );
}
