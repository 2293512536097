import React, { useState, useEffect } from 'react';

function useScrollPosition() {
    const [scrollPosition, setScrollPosition] = useState(0);
  
    useEffect(() => {
      const handleScroll = () => {
        setScrollPosition(window.scrollY);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    return scrollPosition;
}

export default function BackToTopButton() {
    const scrollPosition = useScrollPosition();

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };
  
    return (
      <>
        {scrollPosition > 300 && (
          <button onClick={scrollToTop} className='backtoTop'>
            <span class="material-symbols-outlined">
              keyboard_double_arrow_up
            </span>
          </button>
        )}
      </>
    );
  }



