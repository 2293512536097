import NavigationBar from "./Nav";
import Footer from "./Footer";
import BackToTopButton from './BacktoTop';
import { Slideshow } from "./Slideshow";
import DisableRightClick from './NoRightClick';

import img from './assets/meow/001.svg'
import img2 from './assets/meow/002.svg'
import img3 from './assets/meow/003.svg'
import img4 from './assets/meow/004.svg'
import img5 from './assets/meow/005.svg'
import img6 from './assets/meow/006.svg'
import img7 from './assets/meow/007.svg'
import img8 from './assets/meow/008.svg'
import img9 from './assets/meow/009.svg'
import img10 from './assets/meow/010.svg'
import img11 from './assets/meow/011.svg'
import img12 from './assets/meow/012.svg'
import img13 from './assets/meow/013.svg'
import vid1 from './assets/meow/meowbox.mp4'


const galleryData = {
    title: "Meow box",
    subtitle: "An arduino project",
    images:[
        {src: img, alt: 'title page',},
        {src: img2, alt: 'project overview - section break',},
        {src: img3, alt: 'project overview - details',},
        {src: img4, alt: 'initial design - section break',},
        {src: img5, alt: 'initial design - sketches'},
        {src: img6, alt: 'initial design - process images'},
        {src: img7, alt: 'challenges - section break'},
        {src: img8, alt: 'challenges - details'},
        {src: img9, alt: 'revision history - page one'},
        {src: img10, alt: 'revision history - page two'},
        {src: img11, alt: 'final presentation - section break'},
        {type: 'video', src: vid1},
        {src: img12, alt: 'take aways - section break'},
        {src: img13, alt: 'take aways - thoughts and reflections'},
    ],
}; 

export default function QuantumCat(){
    
    return (
    <div className="slides">
    <NavigationBar/>
    <BackToTopButton/>
    <DisableRightClick>
    <Slideshow title={galleryData.title} subtitle={galleryData.subtitle} images={galleryData.images} /> 
    </DisableRightClick>
    <footer>
        <hr></hr>
        <Footer/>
    </footer>
    </div>
    );
}

  
  
