import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/style.css';
import App from "./App";
import Portfolio from "./Portfolio";
import Resume from "./Resume";
import Centralized from "./CentralizedControl";
import CuzSushi from "./CuzSushi";
import GroceoFind from "./GroceoFind";
import QuantumCat from "./QuantumCat";
import GroceoFindWatch from "./GroceoFindWatch";

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/resume" element={<Resume />} />
      <Route path="/centralized" element={<Centralized />} />
      <Route path="/cuzsushi" element={<CuzSushi />} />
      <Route path="/groceofind" element={<GroceoFind />} />
      <Route path="/quantumcat" element={<QuantumCat />} />
      <Route path="/groceofindwatch" element={<GroceoFindWatch />} />
    </Routes>
  </BrowserRouter>,
);

