import NavigationBar from "./Nav";
import Footer from "./Footer";
import BackToTopButton from './BacktoTop';
import { Slideshow } from "./Slideshow";
import DisableRightClick from './NoRightClick';

import img from './assets/cnvi/001.svg'
import img2 from './assets/cnvi/002.svg'
import img3 from './assets/cnvi/003.svg'
import img4 from './assets/cnvi/004.svg'
import img5 from './assets/cnvi/005.svg'
import img6 from './assets/cnvi/006.svg'
import img7 from './assets/cnvi/007.svg'
import img8 from './assets/cnvi/008.svg'
import img9 from './assets/cnvi/009.svg'
import img10 from './assets/cnvi/010.svg'
import img11 from './assets/cnvi/011.svg'
import img12 from './assets/cnvi/012.svg'


const galleryData = {
    title: "Email Notification",
    subtitle: "Data Visualization for Large Screen",
    images:[
        {src: img, alt: 'title page',},
        {src: img2, alt: 'project overview - introducing the goal',},
        {src: img3, alt: 'initial design section page',},
        {src: img4, alt: 'initial design - page one',},
        {src: img5, alt: 'initial design - page two'},
        {src: img6, alt: 'initial design - page three'},
        {src: img7, alt: 'final design section page'},
        {src: img8, alt: 'final design - detailed explainations for changes made on the initial designs'},
        {src: img9, alt: 'final design - detailed explainations for changes made on the initial designs'},
        {src: img10, alt: 'take aways section page'},
        {src: img11, alt: 'take aways - thoughts and reflections'},
        {src: img12, alt: 'thank you for reviewing my works'}
    ],
}; 

export default function Centralized(){
    
    return (
    <div className="slides">
    {/* <title>Wen Xin Studio | Data Visualization</title> */}
    <NavigationBar/>
    <BackToTopButton/>
    <DisableRightClick>
    <Slideshow title={galleryData.title} subtitle={galleryData.subtitle} images={galleryData.images} /> 
    </DisableRightClick>
    <footer>
        <hr></hr>
        <Footer/>
    </footer>
    </div>
    );
}

  
  
